<template>
  <ul class="sub-navigation pl-0">
    <router-link
      custom v-slot="{ navigate }"
      to="/quotations/create">
      <li
        @click="navigate"
        @keypress.enter="navigate"
        role="link"
        class="seven-of"
        :class="{ active : isNewQuote }">New Quote</li>
    </router-link>
    <router-link
      custom v-slot="{ navigate }"
      to="/quotations/index">
      <li
        @click="navigate"
        @keypress.enter="navigate"
        role="link"
        class="seven-of"
        :class="{ active : isQuotationsIndex }">All Quotes</li>
    </router-link>
    <router-link
        custom v-slot="{ navigate }"
        to="/quotations/awaiting-auth">
      <li
          @click="navigate"
          @keypress.enter="navigate"
          role="link"
          class="seven-of"
          :class="{ active : isAwaitingAuth }">Awaiting Auth</li>
    </router-link>
    <router-link
      custom v-slot="{ navigate }"
      to="/quotations/expired">
      <li
        @click="navigate"
        @keypress.enter="navigate"
        role="link"
        class="seven-of"
        :class="{ active : isQuotationsExpired }">Expired Quotes</li>
    </router-link>
    <router-link
      custom v-slot="{ navigate }"
      to="/quotations/my-open">
      <li
        @click="navigate"
        @keypress.enter="navigate"
        role="link"
        class="seven-of"
        :class="{ active : isMyOpen }">My Open Quotes</li>
    </router-link>
    <router-link
      custom v-slot="{ navigate }"
      to="/quotations/all-open">
      <li
        @click="navigate"
        @keypress.enter="navigate"
        role="link"
        class="seven-of"
        :class="{ active : isAllOpen }">All Open Quotes</li>
    </router-link>
    <router-link
      custom v-slot="{ navigate }"
      to="/quotations/search">
      <li
        @click="navigate"
        @keypress.enter="navigate"
        role="link"
        class="seven-of"
        :class="{ active : isSearch }">Search Quotes</li>
    </router-link>
  </ul>
</template>

<script>
export default {
  name: 'QuotationsNav',
  computed: {
    isQuotationsIndex() {
      return this.$route.name === 'QuotationsIndex';
    },
    isQuotationsExpired() {
      return this.$route.name === 'QuotationsExpired';
    },
    isMyOpen() {
      return this.$route.name === 'QuotationsMyOpen';
    },
    isAllOpen() {
      return this.$route.name === 'QuotationsAllOpen';
    },
    isNewQuote() {
      return this.$route.name === 'QuotationsCreate';
    },
    isSearch() {
      return this.$route.name === 'QuotationsSearch';
    },
  },
};
</script>
