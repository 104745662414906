import Vue from 'vue';
import Vuex from 'vuex';
import Localbase from 'localbase';
import axios from '../axios';

const db = new Localbase('db');
db.config.debug = false;

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    applicationSearchParams: {
      applicationNumber: '',
      customer: '',
      date: null,
      salesPerson: '',
      sageRef: '',
      paymentStatus: '',
    },
    appTitle: process.env.VUE_APP_TITLE,
    calendarType: 1,
    creditNoteSearchParams: {
      customer: '',
      jobNumber: '',
      sageRef: '',
    },
    customerSearchParams: {
      address: '',
      archived: false,
      contactName: '',
      customerName: '',
      type_id: '',
    },
    dashboardSearchParams: {
      customer: '',
      date: '',
      jobNumber: '',
      jobSite: '',
      jobStatus: '',
      sageRef: '',
      salesPerson: '',
    },
    date: (new Date(Date.now() - (new Date()).getTimezoneOffset()
        * 60000)).toISOString().substr(0, 10),
    departmentJobs: [],
    departments: [
      { id: 1, name: 'Thermo' },
      { id: 2, name: 'Paint' },
      { id: 3, name: 'Highways' },
      { id: 4, name: 'Surveys' },
    ],
    departmentVehiclesLength: 0,
    // domain: 'https://staging.yoursafetypal.co.uk',
    domain: 'https://app.yoursafetypal.com',
    drawer: true,
    drsSearchParams: {
      jobNumber: '',
      customer: '',
      salesPerson: '',
      chargehand: '',
      date: [],
    },
    endOfList: false,
    jobs: [],
    foundJobs: [],
    initialDate: null,
    initialTime: '06:00:00',
    initialTimeEnd: '12:00:00',
    invoiceSearchParams: {
      invoiceNumber: '',
      customer: '',
      date: null,
      salesPerson: '',
      sageRef: '',
      paymentStatus: '',
      includeDraftInvoices: 0,
    },
    jobAdded: 0,
    jobClientSearch: '',
    jobDateSearch: '',
    jobNumberSearch: '',
    jobSiteSearch: '',
    jobOrder: '',
    leaveRequestsSearchParams: {
      person: '',
      date: [],
    },
    notes: [],
    plannedJobs: [],
    purchasingSearchParams: {
      cancelled: 2,
      complete: 2,
      date: [],
      open: 1,
      orderNo: '',
      supplier: '',
      description: '',
    },
    quotationSearchParams: {
      approval: 0,
      customer: '',
      date: [],
      jobNumber: '',
      jobSite: '',
      salesPerson: 0,
    },
    section: '',
    selectedJob: 0,
    selectedNoteId: 0,
    selectedPlannedJob: 0,
    selectedSurveyId: 0,
    selectedVehicleDept: '',
    selectedVehicleId: 0,
    selectedVehicleMaintenanceId: 0,
    selectedWorkerId: 0,
    selectedWorkerUnavailableId: 0,
    statementDate: null,
    statementSageRef: '',
    token: '',
    userid: '',
    username: '',
    userPermissions: [],
    vehicleCountStart: 0,
    vehicles: [],
    vehicleSearch: '',
    workers: [],
    workerSearch: '',
  },
  mutations: {
    clearInitialDate(state) {
      state.initialDate = null;
    },
    hideSnackbar(state) {
      state.snackbar.show = false;
    },
    jobAdded(state) {
      state.jobAdded += 1;
    },
    nextVehicles(state, start) {
      state.vehicleCountStart = start;
    },
    prevVehicles(state, start) {
      state.vehicleCountStart = start;
    },
    selectJob(state, jobId) {
      state.selectedJob = jobId;
    },
    selectNote(state, noteId) {
      state.selectedNoteId = noteId;
    },
    selectPlannedJob(state, jobId) {
      state.selectedPlannedJob = jobId;
    },
    selectVehicle(state, vehicleId) {
      state.selectedVehicleId = vehicleId;
    },
    selectVehicleMaintence(state, maintenanceId) {
      state.selectedVehicleMaintenanceId = maintenanceId;
    },
    selectWorker(state, workerId) {
      state.selectedWorkerId = workerId;
    },
    setApplicationSearchParams(state, params) {
      state.applicationSearchParams = params;
    },
    setCalendarType(state, type) {
      state.calendarType = type;
    },
    setConvertedJobs(state, convertedJobs) {
      state.jobs = convertedJobs;
    },
    setCredentials(state, credentials) {
      state.token = credentials.json.token;
      state.userid = credentials.user;
      state.username = credentials.name;
      if (credentials.permissions) {
        state.userPermissions = credentials.permissions;
      } else {
        state.userPermissions = [];
      }
    },
    setCreditNoteSearchParams(state, params) {
      state.creditNoteSearchParams = params;
    },
    setCustomerSearchParams(state, params) {
      state.customerSearchParams = params;
    },
    setDashboardSearchParams(state, params) {
      state.dashboardSearchParams = params;
    },
    setDate(state, date) {
      state.date = date;
    },
    setDepartmentJobs(state, jobs) {
      state.departmentJobs = jobs;
    },
    setDeptVehicleLength(state, vehicleLength) {
      state.departmentVehiclesLength = vehicleLength;
    },
    setDrsSearchParams(state, params) {
      state.drsSearchParams = params;
    },
    setFoundJobs(state, foundJobs) {
      state.foundJobs = foundJobs;
    },
    setInitialDate(state, dates) {
      state.initialDate = dates;
    },
    setInitialDateArray(state, dates) {
      state.initialDate = [];
      state.initialDate.push(dates);
    },
    setInitialTime(state, time) {
      state.initialTime = time;
    },
    setInitialTimeEnd(state, time) {
      state.initialTimeEnd = time;
    },
    setInvoiceSearchParams(state, params) {
      state.invoiceSearchParams = params;
    },
    setJobClientSearch(state, value) {
      state.jobClientSearch = value;
    },
    setJobNumberSearch(state, value) {
      state.jobNumberSearch = value;
    },
    setJobOrder(state, value) {
      state.jobOrder = value;
    },
    setJobSiteSearch(state, value) {
      state.jobSiteSearch = value;
    },
    setLeaveRequestsSearchParams(state, params) {
      state.leaveRequestsSearchParams = params;
    },
    setNotes(state, notes) {
      state.notes = notes;
    },
    setPlannedJobs(state, plannedJobs) {
      state.plannedJobs = plannedJobs;
    },
    setPurchasingSearchParams(state, params) {
      state.purchasingSearchParams = params;
    },
    setQuotationSearchParams(state, params) {
      state.quotationSearchParams = params;
    },
    setSatementParams(state, params) {
      state.statementDate = params.statementDate;
      state.statementSageRef = params.statementSageRef;
    },
    setSection(state, section) {
      state.section = section;
    },
    setSurvey(state, value) {
      state.selectedSurveyId = value;
    },
    setUp(state, payload) {
      state.vehicles = payload.vehicles;
      state.workers = payload.workers;
      state.jobs = payload.convertedJobs;
      state.notes = payload.notes;
      state.departmentVehiclesLength = payload.vehicles.length;
    },
    setVehicleDept(state, dept) {
      state.vehicleCountStart = 0;
      state.selectedVehicleDept = dept;
    },
    setVehicles(state, vehicles) {
      state.vehicles = vehicles;
    },
    setVehicleSearch(state, value) {
      state.vehicleSearch = value;
    },
    setWorkers(state, workers) {
      state.workers = workers;
    },
    setWorkerSearch(state, value) {
      state.workerSearch = value;
    },
    setWorkerUnavailable(state, value) {
      state.selectedWorkerUnavailableId = value;
    },
    showSnackbar(state, text) {
      let duration = 0;
      if (state.snackbar.show === true) {
        state.snackbar.show = false;
        duration = 300;
      }
      setTimeout(() => {
        state.snackbar.show = true;
        state.snackbar.text = text;
      }, duration);
    },
    toggleDrawer(state) {
      state.drawer = !state.drawer;
    },
    unsetCredentials(state) {
      state.token = '';
      state.userid = '';
      state.username = '';
      state.userPermissions = '';
    },
    useCredentials(state, credentials) {
      state.token = credentials.token;
      state.userid = credentials.id;
      state.username = credentials.name;
      if (credentials.permissions) {
        state.userPermissions = credentials.permissions;
      } else {
        state.userPermissions = [];
      }
    },
  },
  actions: {
    addPlannedJob({ commit, state }, payload) {
      const tempPlannedJobs = state.plannedJobs;
      tempPlannedJobs.push(payload);
      commit('setPlannedJobs', tempPlannedJobs);
    },
    nextVehicles({ commit, state }) {
      let start = state.vehicleCountStart;
      const end = (start + 10);
      const max = state.vehicles.length;
      start = end;
      if (start > max) {
        start = (end - 10);
      }
      commit('nextVehicles', start);
    },
    prevVehicles({ commit, state }) {
      let start = state.vehicleCountStart;
      const end = (start - 10);
      if (start >= 10) {
        start = end;
      }
      if (start < 10) {
        start = 0;
      }
      commit('prevVehicles', start);
    },
    refreshConvertedJobs({ commit, state }) {
      axios.get(`/planningBoard/fetchAllConvertedJobs.json?token=${state.token}`)
          .then((response) => {
            commit('setConvertedJobs', response.data);
          });
    },
    refreshNotes({ commit, state }) {
      axios.get(`/notes/fetchAll.json?token=${state.token}`)
          .then((response) => {
            commit('setNotes', response.data);
          });
    },
    refreshPlannedJobs({ commit, state }) {
      axios.get(`/plannedJobs/getWeekEvents.json?token=${state.token}date=${state.date}`)
          .then((response) => {
            commit('setPlannedJobs', response.data);
          });
    },
    refreshVehicles({ commit, state }) {
      axios.get(`/vehicles/fetchAll.json?token=${state.token}`)
          .then((response) => {
            commit('setVehicles', response.data);
            commit('setDeptVehicleLength', response.data.length);
          });
    },
    refreshWorkers({ commit, state }) {
      axios.get(`/workers/fetchAll.json?token=${state.token}`)
          .then((response) => {
            commit('setWorkers', response.data);
          });
    },
    selectJob({ commit }, jobId) {
      commit('selectJob', jobId);
      commit('clearInitialDate');
    },
    setCredentials({ commit }, credentials) {
      commit('setCredentials', credentials);
      db.collection('users').doc({ id: credentials.user }).get().then((document) => {
        if (document) {
          db.collection('users').doc({ id: document.id }).set({
            id: credentials.user,
            name: credentials.name,
            token: credentials.json.token,
            permissions: credentials.permissions,
          });
        } else {
          db.collection('users').add({
            id: credentials.user,
            name: credentials.name,
            token: credentials.json.token,
            permissions: credentials.permissions,
          });
        }
      });
    },
    setVehicleDept({ commit, state }, dept) {
      commit('setVehicleDept', dept);
      if (dept !== '') {
        const departmentVehicles = state.vehicles.filter((vehicle) => vehicle.department === dept);
        commit('setDeptVehicleLength', departmentVehicles.length);
      } else {
        commit('setDeptVehicleLength', state.vehicles.length);
      }
      let jobsToReturn = [];
      let jobsByDepartment = state.jobs;
      if (!state.jobClientSearch && !state.jobNumberSearch && !state.jobDateSearch && state.selectedVehicleDept === '') {
        jobsToReturn = state.jobs;
      }
      if (state.selectedVehicleDept === 'Paint') {
        jobsByDepartment = state.jobs.filter((job) => job.department === 1);
      }
      if (state.selectedVehicleDept === 'Thermo') {
        jobsByDepartment = state.jobs.filter((job) => job.department === 2);
      }
      jobsToReturn = jobsByDepartment;
      if (state.jobClientSearch) {
        jobsToReturn = jobsByDepartment.filter((job) => job.clientName.toLowerCase().includes(
            state.jobClientSearch.toLowerCase(),
        ));
      }
      if (state.jobNumberSearch) {
        jobsToReturn = jobsByDepartment.filter((job) => job.jobNumber.toLowerCase().includes(
            state.jobNumberSearch.toLowerCase(),
        ));
      }
      if (state.jobOrder === 'ascending') {
        jobsToReturn.sort((a, b) => {
          const date1 = new Date(a.created);
          const date2 = new Date(b.created);
          return date1 - date2;
        });
      }
      if (state.jobOrder === 'descending') {
        jobsToReturn.sort((a, b) => {
          const date1 = new Date(a.created);
          const date2 = new Date(b.created);
          return date2 - date1;
        });
      }
      commit('setDepartmentJobs', jobsToReturn);
    },
    setVehicleSearch({ commit }, term) {
      commit('nextVehicles', 0);
      commit('setVehicleSearch', term);
    },
    setWeekEvents({ commit }, events) {
      commit('setPlannedJobs', events);
    },
    unsetCredentials({ commit }) {
      commit('unsetCredentials');
      db.collection('users').delete();
    },
    updatePlannedJob({ commit, state }, payload) {
      const tempPlannedJobs = state.plannedJobs;
      tempPlannedJobs[payload.index] = payload;
      commit('setPlannedJobs', tempPlannedJobs);
    },
  },
  getters: {
    getJob(state) {
      if (state.foundJobs.length > 0) {
        return state.foundJobs.filter((job) => job.id === state.selectedJob)[0];
      }
      return state.jobs.filter((job) => job.id === state.selectedJob)[0];
    },
    getPlannedJob(state) {
      return state.plannedJobs.filter((job) => job.id === state.selectedPlannedJob)[0];
    },
    vehicleCategories(state) {
      let vehicleList = [];
      const allVehicles = state.vehicles;
      if (!state.vehicleSearch) {
        if (state.selectedVehicleDept) {
          vehicleList = allVehicles.filter((vcl) => vcl.department === state.selectedVehicleDept);
        } else {
          vehicleList = allVehicles;
        }
      } else {
        vehicleList = allVehicles.filter((vehicle) => vehicle.reg.toLowerCase().includes(
            state.vehicleSearch.toLowerCase(),
        ));
      }
      const vehicles = [];
      const loopStart = state.vehicleCountStart;
      let loopEnd = loopStart + 11;
      if (vehicleList.length < loopEnd) {
        loopEnd = vehicleList.length;
      }
      let i = loopStart;
      if (vehicleList.length > 0) {
        while (i < loopEnd) {
          const value = {};
          value.reg = vehicleList[i].reg;
          value.chargehand = vehicleList[i].chargehandName;
          value.worker = vehicleList[i].worker2Name;
          value.worker2 = vehicleList[i].worker3Name;
          value.worker3 = vehicleList[i].worker4Name;
          vehicles.push(value);
          i += 1;
        }
      }
      return vehicles;
    },
    vehicleCategoryLength(state) {
      let vehicleList = [];
      const allVehicles = state.vehicles;
      if (!state.vehicleSearch) {
        if (state.selectedVehicleDept) {
          vehicleList = allVehicles.filter((vcl) => vcl.department === state.selectedVehicleDept);
        } else {
          vehicleList = allVehicles;
        }
      } else {
        vehicleList = allVehicles.filter((vehicle) => vehicle.reg.toLowerCase().includes(
            state.vehicleSearch.toLowerCase(),
        ));
      }
      return Math.ceil(vehicleList.length / 11);
    },
    selectedVehicle(state) {
      if (state.selectedVehicleId > 0) {
        const allVehicles = state.vehicles;
        const vehicle = allVehicles.filter((vcl) => vcl.id === state.selectedVehicleId)[0];
        if (vehicle.chargehand) {
          const chargehand = state.workers.filter((worker) => worker.id === vehicle.chargehand)[0];
          vehicle.chargehandName = chargehand.name;
        }
        if (vehicle.worker2) {
          const worker2 = state.workers.filter((worker) => worker.id === vehicle.worker2)[0];
          vehicle.worker2Name = worker2.name;
        }
        if (vehicle.worker3) {
          const worker3 = state.workers.filter((worker) => worker.id === vehicle.worker3)[0];
          vehicle.worker3Name = worker3.name;
        }
        if (vehicle.worker4) {
          const worker4 = state.workers.filter((worker) => worker.id === vehicle.worker4)[0];
          vehicle.worker4Name = worker4.name;
        }
        return vehicle;
      }
      const vehicle = {
        chargehand: {},
      };
      return vehicle;
    },
    selectedWorker(state) {
      if (state.selectedWorkerId > 0) {
        const worker = state.workers.filter((wrkr) => wrkr.id === state.selectedWorkerId)[0];
        return worker;
      }
      const worker = {};
      return worker;
    },
    filteredVehicles(state) {
      const allVehicles = state.vehicles;
      if (!state.vehicleSearch) {
        return allVehicles;
      }
      return allVehicles.filter((vehicle) => vehicle.reg.toLowerCase().includes(
          state.vehicleSearch.toLowerCase(),
      ));
    },
    filteredConvertedJobs(state) {
      let jobsToReturn = [];
      let jobsByDepartment = state.jobs;
      if (!state.jobClientSearch && !state.jobNumberSearch && !state.jobDateSearch && state.selectedVehicleDept === '') {
        jobsToReturn = state.jobs;
      }
      if (state.selectedVehicleDept === 'Paint') {
        jobsByDepartment = state.jobs.filter((job) => job.department === 1);
      }
      if (state.selectedVehicleDept === 'Thermo') {
        jobsByDepartment = state.jobs.filter((job) => job.department === 2);
      }
      jobsToReturn = jobsByDepartment;
      if (state.jobClientSearch) {
        jobsToReturn = jobsByDepartment.filter((job) => job.clientName.toLowerCase().includes(
            state.jobClientSearch.toLowerCase(),
        ));
      }
      if (state.jobNumberSearch) {
        jobsToReturn = jobsByDepartment.filter((job) => job.jobNumber.toLowerCase().includes(
            state.jobNumberSearch.toLowerCase(),
        ));
      }
      if (state.jobOrder === 'ascending') {
        jobsToReturn.sort((a, b) => {
          const date1 = new Date(a.created);
          const date2 = new Date(b.created);
          return date1 - date2;
        });
      }
      if (state.jobOrder === 'descending') {
        jobsToReturn.sort((a, b) => {
          const date1 = new Date(a.created);
          const date2 = new Date(b.created);
          return date2 - date1;
        });
      }
      return jobsToReturn;
    },
    filteredWorkers(state) {
      if (!state.workerSearch) {
        return state.workers;
      }
      return state.workers.filter((worker) => worker.name.toLowerCase().includes(
          state.workerSearch.toLowerCase(),
      ));
    },
    getNote(state) {
      const note = state.notes.filter((nt) => nt.id === state.selectedNoteId)[0];
      const vehicle = state.vehicles.filter((vh) => vh.id === note.vehicle_id)[0];
      note.vehicle = vehicle.reg;
      return note;
    },
  },
});
