import axios from 'axios';

export default axios.create({
  // Dev
  // baseURL: 'https://riggott-ims-api.mmandb-dev.co.uk',
  // Staging
  // baseURL: 'https://staging-api.riggott-ims.co.uk',
  // Production
  baseURL: 'https://api.riggott-ims.co.uk',
  headers: {
    'Content-type': 'application/json',
  },
});
